import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import Chart from '../../../../Chart/Chart'
import request from '../../../../request';


export default function Price({ coupon, product, onClose }) {
    const [price, setPrice] = useState([])
    useEffect(() => {
        (async () => {
            const data = await request(`/price/${coupon.id}`, { method: 'post' })
            const prices = await data.json()
            const sortedPrices = prices.sort((a, b) => {
                const dateA = new Date(a.year, a.month - 1, a.date)
                const dateB = new Date(b.year, b.month - 1, b.date)
                return dateA - dateB;
            })
            setPrice(sortedPrices)
        })()
    }, [])


    return <>
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    {product.name + ' - ' + coupon.sku}
                </Typography>
            </Toolbar>
        </AppBar>
        {price && <Chart prices={price} />}
    </>
}