import * as React from 'react';
import Paper from '@mui/material/Paper';
import styled from '@mui/material/styles/styled';

const DemoPaper = styled(Paper)(({ theme }) => ({
    width: 70,
    height: 70,
    padding: theme.spacing(2),
    ...theme.typography.body2,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
}));

export default function ProductTab({ product, active }) {
    return <DemoPaper elevation={active ? 24 : 1} >
        <img style={{ maxHeight: '80%' }} src={product.iconURL} />
        <div style={{ fontSize: '13px', lineHeight: '1em', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {product.name}
        </div>
    </DemoPaper>
}
