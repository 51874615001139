import { useEffect, useState, forwardRef } from 'react'

import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import Chip from '@mui/material/Chip';
import Slide from '@mui/material/Slide';

import CouponItem from './CouponItem/CouponItem';
import Price from './Price/Price';
import request from '../../../request'


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Coupon({ product }) {
    const [coupons, setCoupons] = useState([])
    const [channels, setChannels] = useState([])
    useEffect(() => {
        (async () => {
            const data = await request(`/coupon?product_id=${product.id}`)
            const jsonData = await data.json()
            setCoupons(jsonData)
            const set = new Set()
            for (const d of jsonData) {
                set.add(d.channel)
            }
            setChannels(Array.from(set))
        })()
    }, [])

    const [currentChannel, setCurrentChannel] = useState('')
    const handleChannel = channel => {
        setCurrentChannel(channel)
    }
    const handleClearChannel = () => {
        setCurrentChannel('')
    }

    const [openID, setOpenID] = useState(0)
    const handleClickOpen = couponID => {
        setOpenID(couponID);
    };
    const handleClose = () => {
        setOpenID(0);
    };

    return <>
        <Grid container spacing={2} margin='0 auto'>
            <Grid item>
                <Chip label='全部'
                    color='primary'
                    {...(!currentChannel ? {} : { variant: "outlined" })}
                    onClick={handleClearChannel}
                />
            </Grid>
            {channels && channels.map(channel => (
                channel && <Grid item key={channel}>
                    <Chip label={channel}
                        color='primary'
                        {...(currentChannel === channel ? {} : { variant: "outlined" })}
                        onClick={() => handleChannel(channel)}
                    />
                </Grid>
            ))}
        </Grid>

        <Grid container spacing={2} justifyContent='start' margin='0 auto' >
            {coupons && coupons.map(coupon => (
                coupon.channel.includes(currentChannel) && <Grid item key={coupon.id}>
                    <CouponItem
                        onClick={() => handleClickOpen(coupon.id)}
                        coupon={coupon}
                    />
                </Grid>
            ))}
        </Grid>

        {coupons.map(coupon => (
            <Dialog
                fullScreen
                key={coupon.id}
                open={openID === coupon.id}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <Price open={coupon.id === openID} onClose={handleClose} coupon={coupon} product={product} />
            </Dialog>
        ))}
    </>
}
