import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';

export default function CouponItem({ coupon, onClick }) {
    return <Card sx={{ width: 190, height: 190 }}>
        <CardActionArea onClick={onClick}>
            <CardMedia
                component="img"
                style={{ height: 120 }}
                image={coupon.imgURL}
            />
            <CardContent>
                <Typography sx={{ fontSize: '14px', whiteSpace: 'nowrap' }} gutterBottom variant="h5" component="div">
                    {coupon.sku}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {coupon.desc}
                </Typography>
            </CardContent>
        </CardActionArea>
    </Card>
}
