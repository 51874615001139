import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Product from './Product/Product';

import request from '../request';

const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, ...props.sx }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function VerticalTabs() {
    const [value, setValue] = React.useState(0);
    const [categories, setCategories] = React.useState(0)

    React.useEffect(() => {
        (async () => {
            const data = await request('/category')
            setCategories(await data.json())
        })()
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
        >
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                sx={{ borderRight: 1, borderColor: 'divider', minWidth: 100 }}
            >
                {categories && categories.map(category => (
                    <Tab key={category.id} label={category.name} />
                ))}
            </Tabs>
            {categories && categories.map((category, index) => (
                <TabPanel key={category.id} value={value} index={index}>
                    <Product key={category.id} categoryID={category.id} />
                </TabPanel>))
            }
        </Box >
    );
}