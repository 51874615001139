import React from 'react'

import Grid from '@mui/material/Grid'
import ProductTab from './ProductTab/ProductTab'
import request from '../../request'

import Coupon from './Coupon/Coupon'

export default function Product({ categoryID }) {
    const [products, setProducts] = React.useState([])
    const [value, setValue] = React.useState(0)

    const changeProduct = index => {
        setValue(index)
    }

    React.useEffect(() => {
        (async () => {
            const data = await request(`/product?category_id=${categoryID}`)
            setProducts(await data.json())
        })()
    }, [categoryID])

    return <>
        <Grid container spacing={2} margin='0 auto'>
            {products && products.map((product, index) => (
                <Grid item key={product.id} onClick={() => changeProduct(index)}>
                    <ProductTab active={index === value} key={product.id} product={product} />
                </Grid>
            ))}
        </Grid >

        {products && products.map((product, index) => (
            index === value && <Coupon key={product.id} product={product} />
        ))}
    </>
}
