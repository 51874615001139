import { LineChart } from '@mui/x-charts/LineChart';

export default function Chart({ prices }) {
    const xLabels = []
    const priceArr = []
    
    for (const price of prices) {
        priceArr.push(price.averagePrice / 100)
        xLabels.push(new Date(price.year, price.month - 1, price.date).toLocaleDateString())
    }

    return <LineChart
        series={[
            { data: priceArr },
        ]}
        xAxis={[{ scaleType: 'point', data: xLabels }]}
    />
}